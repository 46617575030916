<template>
  <div class="notYetOpen" />
</template>

<script>
export default {
  name: 'NotYetOpen'
}
</script>

<style scoped lang="less">
.notYetOpen{
  height: 100%;
  width: 100%;
  background: #FFFFFF url(../../assets/images/notOpen.png) no-repeat fixed center;
}
</style>
